<template>
    <div class="medical_detail_page">
        <van-row class="medical_title_line">
            <van-col span="5" class="title">姓名</van-col>
            <van-col span="7" class="content">{{name || '--'}}</van-col>
            <van-col span="5" class="title">性别</van-col>
            <van-col span="7" class="content">{{sex || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="title">就诊时间</van-col>
            <van-col span="7" class="content">{{seetime || '--'}}</van-col>
            <van-col span="5" class="title">诊所</van-col>
            <van-col span="7" class="content">{{clinicname || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="title">电话</van-col>
            <van-col span="7" class="content">{{phone || '--'}}</van-col>
            <van-col span="5" class="title">年龄</van-col>
            <van-col span="7" class="content">{{age || '--'}}岁</van-col>
        </van-row>
        <van-row class="medical_title_line" v-if="doctor">
            <van-col span="5" class="left">开单医生</van-col>
            <van-col span="19" class="right" offset="5">{{doctor}}</van-col>
        </van-row>
        <van-row class="medical_title_line" v-if="address">
            <van-col span="5" class="left">地址</van-col>
            <van-col span="19" class="right" offset="5">{{address}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="left">主诉</van-col>
            <van-col span="19" class="right" offset="5">{{complaint || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="left">现病史</van-col>
            <van-col span="19" class="right" offset="5">{{medicalhistory || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line" v-if="commonsigns">
            <van-col span="5" class="left">常见体征</van-col>
            <van-col span="19" class="right" offset="5">{{commonsigns}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="left">舌苔</van-col>
            <van-col span="19" class="right" offset="5">{{tongue || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="left">脉象</van-col>
            <van-col span="19" class="right" offset="5">{{pulsecondition || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="left">中医诊断</van-col>
            <van-col span="19" class="right" offset="5">{{diagnose || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="left">西医诊断</van-col>
            <van-col span="19" class="right" offset="5">{{westerndiagnostics || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line" v-if="plist && plist.length > 0">
            <van-col span="5" class="left">中医药方</van-col>
            <van-col span="19" class="right" offset="5">
                <div class="one" v-for="(item, i) in plist" :key="i">
                    <div class="name">处方{{i + 1}}</div>
                    <div>
                        药方明细：{{item.prescription}}
                    </div>
                    <div class="dosage">
                        <span>自煎：{{item.dosage || '0'}}</span>
                        <span>代煎：{{item.replacedosage || '0'}}</span>
                        <span>制法：{{item.preparation}}</span>
                    </div>
                    <div>
                        医嘱：{{item.doctorremark || '--'}}
                    </div>
                    <div>
                        煎服法：{{item.decoctionmethod || '--'}}
                    </div>
                </div>
            </van-col>
        </van-row>
        <van-row class="medical_title_line" v-if="tlist && tlist.length > 0">
            <van-col span="5" class="left">理疗单</van-col>
            <van-col span="19" class="right" offset="5">
                <div class="one" v-for="(item, i) in tlist" :key="i">
                    <div class="name">理疗单{{i + 1}}</div>
                    <div>
                        {{item.info}}
                    </div>
                </div>
            </van-col>
        </van-row>
        <van-row class="medical_title_line" v-if="slist && slist.length > 0">
            <van-col span="5" class="left">服务单</van-col>
            <van-col span="19" class="right" offset="5">
                <div class="one" v-for="(item, i) in slist" :key="i">
                    <div class="name">服务单{{i + 1}}</div>
                    <div>
                        {{item.info}}
                    </div>
                </div>
            </van-col>
        </van-row>
        <van-row class="medical_title_line" v-if="clist && clist.length > 0">
            <van-col span="5" class="left">检查单</van-col>
            <van-col span="19" class="right" offset="5">
                <div class="one" v-for="(item, i) in clist" :key="i">
                    <div class="name">检查单{{i + 1}}</div>
                    <div>
                        {{item.info}}
                    </div>
                </div>
            </van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="left">出现反应</van-col>
            <van-col span="19" class="right" offset="5">{{showedrespones || '--'}}</van-col>
        </van-row>
        <van-row class="medical_title_line">
            <van-col span="5" class="left">其他</van-col>
            <van-col span="19" class="right" offset="5">{{other || '--'}}</van-col>
        </van-row>
    </div>
</template>

<script>
    import { wxmedicaldetailqry } from '@/api/medical'
    import { mapGetters } from 'vuex'
    import { Toast } from 'vant'

    export default {
        computed: {
            ...mapGetters([
                'username'
            ]),
        },
        data() {
            return {
                userid: '',
                customerid:'',
                id: '',
                type: '',
                name : '',
                sex: '',
                age: '',
                phone: '',
                seetype: '',
                seetime:'',
                complaint:'',
                diagnose:'',
                doctor:'',
                clinicname:'',
                clinicid:'',
                address:'',
                medicalhistory:'',
                commonsigns:'',
                tongue:'',
                pulsecondition:'',
                westerndiagnostics:'',
                showedrespones:'',
                other:'',
                plist:[],
                tlist:[],
                slist:[],
                clist:[]
            }
        },
        mounted () {
            this.$store.dispatch('hideOrShowNav', false)
            this.id = this.$route.query.id || ''
            this.type = this.$route.query.type || ''
            this.userid = this.$route.query.userid || ''
            this.customerid = this.$route.query.customerid || ''
            this.medicalDetailQry()
        },
        methods: {
            // 获取订单信息
            medicalDetailQry(){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                let data = {
                    username: this.username,
                    userid: this.userid,
                    customerid: this.customerid,
                    id: this.id,
                    type: this.type
                }
                wxmedicaldetailqry(data).then(response => {
                    Toast.clear()
                    this.detailinfo = response.response_body
                    this.name = response.response_body.name
                    this.sex = response.response_body.sex
                    this.age = response.response_body.age
                    this.phone = response.response_body.phone
                    this.seetype = response.response_body.seetype
                    this.seetime = response.response_body.seetime
                    this.complaint = response.response_body.complaint
                    this.diagnose = response.response_body.diagnose
                    this.doctor = response.response_body.doctor
                    this.clinicname = response.response_body.clinicname
                    this.clinicid = response.response_body.clinicid
                    this.address = response.response_body.address
                    this.medicalhistory = response.response_body.medicalhistory
                    this.commonsigns = response.response_body.commonsigns
                    this.tongue = response.response_body.tongue
                    this.pulsecondition = response.response_body.pulsecondition
                    this.westerndiagnostics = response.response_body.westerndiagnostics
                    this.showedrespones = response.response_body.showedrespones
                    this.other = response.response_body.other
                    this.plist = response.response_body.plist || []
                    this.tlist = response.response_body.tlist || []
                    this.slist = response.response_body.slist || []
                    this.clist = response.response_body.clist || []
                })
            },
            //返回
            back() {
                this.$router.go(-1)
            },
        },
    }
</script>

<style lang="scss" scoped>
.medical_title_line{
    .right{
        word-break: break-all;
    }
}
</style>